.card-container {
  border-radius: 25px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  height: 450px;
  transition: 0.3s;
}

.card-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.card-container img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  margin: auto;
}

.card-container p {
  color: #404655;
  overflow: auto;
  height: 130px;
  text-align: center;
  margin: auto;
}

.card-footer {
  background-color: #fff;
}
